import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../api';
import SupplyPieChart from './SupplyPieChart';

const formatMarketCap = (marketCap) => {
  if (!marketCap) return 'N/A';
  if (marketCap >= 1e9) {
    return `${(marketCap / 1e9).toFixed(2)}B`;
  }
  if (marketCap >= 1e6) {
    return `${(marketCap / 1e6).toFixed(2)}M`;
  }
  return `${marketCap.toLocaleString()}`;
};

const CoinDetail = () => {
  const { id } = useParams();
  const [coin, setCoin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.getCoin(id)
      .then(data => {
        setCoin(data.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!coin) return <div>Coin not found</div>;

  return (
    <div className="coin-detail-container">
      <table className="coin-detail-table">
        <tbody>
          <tr>
            <td><h1>{coin.symbol}</h1> 
            
              {coin.whitePaperUrl ? (
                <a href={coin.whitePaperUrl} target="_blank" rel="noopener noreferrer">
                <h2>{coin.name}</h2>
                </a>
              ) : (
                'Not available'
              )}
            </td>
            <td>
              {coin.featuredImage ? (
                <img 
                  src={`${process.env.REACT_APP_STRAPI_URL}${
                    coin.featuredImage.formats?.small?.url || coin.featuredImage.url
                  }`}
                  alt={coin.name}
                  style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                />
              ) : (
                <div 
                  style={{ 
                    width: '100px', 
                    height: '100px', 
                    backgroundColor: '#eee',
                    borderRadius: '50%'
                  }} 
                />
              )}
              
            </td>
          </tr>
          <tr>
            <td><b>Price</b></td>
            <td><b>Market Cap</b><br />${formatMarketCap(coin.marketCap)}</td>
          </tr>
          <tr>
            <td>
                <p><b>Circulating Supply</b><br />{formatMarketCap(coin.circulatingSupply)}</p>
                <p><b>Max Supply</b><br />{formatMarketCap(coin.maxSupply || 'Unlimited')}</p>
            </td>
            <td>
              <div style={{display: 'inline-block', verticalAlign: 'middle' }}>
                <SupplyPieChart 
                  circulatingSupply={coin.circulatingSupply}
                  maxSupply={coin.maxSupply}    
                  svgSize={100}
                  radius={38}
                  strokeWidth={24}
                  displaySupply={false}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
            <div style={{ textAlign: 'left', padding: '20px' }}>
              {coin.description?.map((block, index) => {
                if (block.type === 'paragraph') {
                  return <p key={index}>
                    {block.children?.map((child, childIndex) => 
                      <span key={childIndex}>{child.text}</span>
                    )}
                  </p>;
                }
                if (block.type === 'list') {
                  return <ul key={index}>
                    {block.children?.map((item, itemIndex) => 
                      <li key={itemIndex}>{item.children[0].text}</li>
                    )}
                  </ul>;
                }
                return null;
              })}
            </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CoinDetail; 