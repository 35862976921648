const API_URL = process.env.REACT_APP_STRAPI_URL;

const get = async (endpoint) => {
  const fullUrl = `${API_URL}${endpoint}`;
  console.log('Calling API:', fullUrl); // Debug URL

  if (!API_URL) {
    throw new Error('REACT_APP_STRAPI_URL is not configured');
  }

  try {
    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
      },
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('API Call Failed:', error);
    throw error;
  }
};

export default {
  get,
  getCoins: () => get('/api/coins?populate=*'),
  getCoin: (id) => get(`/api/coins/${id}?populate=*`)
};
