const SupplyPieChart = ({ 
  circulatingSupply, 
  maxSupply, 
  svgSize = 50,
  radius = 20,
  strokeWidth = 4,
  displaySupply = true
}) => {

  // Original pie chart code for when maxSupply exists
  const percentage = (circulatingSupply / maxSupply) * 100;
  const circumference = 2 * Math.PI * radius;
  const dashArray = (percentage * circumference) / 100;

  // Format supply numbers (in billions or millions for readability)
  const formatSupply = (supply) => {
    if (!supply) return 'Unlimited';
    if (supply >= 1e9) {
      return `${(supply / 1e9).toFixed(2)}B`;
    }
    if (supply >= 1e6) {
      return `${(supply / 1e6).toFixed(2)}M`;
    }
    return supply.toLocaleString();
  };

  // If maxSupply is null or undefined, return infinity symbol
    if (!maxSupply) {
    return (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '10px' 
      }}>
        <div style={{ 
          width: `${svgSize}px`,
          height: `${svgSize}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: `${svgSize}px`,
          color: '#BBBB55',
          margin: '0 auto'
        }}>
          ∞
        </div>
        {displaySupply && (<div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          fontSize: '0.75rem',
          textAlign: 'center',
          minWidth: '80px'
        }}>
         {displaySupply && (<div>Circ: {formatSupply(circulatingSupply)}</div>)}
         {displaySupply && (<div>Max: {formatSupply(maxSupply)}</div>)}
        </div>)}
      </div>
    );
  }

  
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
      <div style={{ 
        width: `${svgSize}px`, 
        height: `${svgSize}px`, 
        margin: '0 auto',
        position: 'relative'
      }}>
        <svg 
          width={svgSize} 
          height={svgSize} 
          viewBox={`0 0 ${svgSize} ${svgSize}`}
        >
          {/* Background circle */}
          <circle
            cx={svgSize/2}
            cy={svgSize/2}
            r={radius}
            fill="none"
            stroke="#666"
            strokeWidth={strokeWidth}
          />
          {/* Foreground circle (percentage) */}
          <circle
            cx={svgSize/2}
            cy={svgSize/2}
            r={radius}
            fill="none"
            stroke="#BBBB55"
            strokeWidth={strokeWidth}
            strokeDasharray={`${dashArray} ${circumference}`}
            transform={`rotate(-90 ${svgSize/2} ${svgSize/2})`}
          />
        </svg>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '0.875rem',
          fontWeight: 'bold'
        }}>
          {percentage.toFixed(0)}%
        </div>
      </div>
      {/* Supply numbers */}
      {displaySupply && (<div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        fontSize: '0.75rem',
      }}>
        {displaySupply && (<div>Circ: {formatSupply(circulatingSupply)}</div>)}
        {displaySupply && (<div>Max: {formatSupply(maxSupply)}</div>)}

      </div>)}
    </div>
  );
};

export default SupplyPieChart;