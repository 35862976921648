import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import api from './api';
import SupplyPieChart from './components/SupplyPieChart';
import CoinDetail from './components/CoinDetail';


const formatMarketCap = (marketCap) => {
  if (!marketCap) return 'N/A';
  if (marketCap >= 1e9) {
    return `$${(marketCap / 1e9).toFixed(2)}B`;
  }
  if (marketCap >= 1e6) {
    return `$${(marketCap / 1e6).toFixed(2)}M`;
  }
  return `$${marketCap.toLocaleString()}`;
};

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<CoinList />} />
          <Route path="/coin/:id" element={<CoinDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

function CoinList() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    api.getCoins()
      .then(response => {
        console.log('Full response:', response);
        setData(response);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!data || !data.data) return <div>No data available</div>;

  return (
    <div className="coin-table-container">
      <h1><i>CryptoFinder</i></h1>
      <table className="coin-table">
        <thead>
          <tr>
            <th colSpan="3">Coin</th>
            <th>Market Cap </th>
            <th>Supply</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((coin) => {
            const attributes = coin.attributes || coin;
            return (
              <tr 
                key={attributes.documentId}
                onClick={() => navigate(`/coin/${attributes.documentId}`)}
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {attributes.featuredImage ? (
                    <img 
                      src={`${process.env.REACT_APP_STRAPI_URL}${
                        attributes.featuredImage.formats?.thumbnail?.url || attributes.featuredImage.url
                      }`}
                      alt={attributes.name}
                      style={{ width: '30px', height: '30px', objectFit: 'contain' }}
                    />
                  ) : (
                    <div 
                      style={{ 
                        width: '30px', 
                        height: '30px', 
                        backgroundColor: '#eee',
                        borderRadius: '50%'
                      }} 
                    />
                  )}
                </td>
                <td>{attributes.symbol}</td>
                <td>{attributes.name}</td>
                <td>{formatMarketCap(attributes.marketCap)}</td>
                <td>
                  <SupplyPieChart 
                    circulatingSupply={attributes.circulatingSupply}
                    maxSupply={attributes.maxSupply}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
